import { BROCHURE_LINK, CONSOLE_LOG, HUBSPOT_API_KEY } from "./constants";
import { fetchDataFromPHP } from "./module";


function handleSubmit(buttonId, formId) {
    const button = document.getElementById(buttonId);
    if (button) {
        button.addEventListener('click', async () => {
            // Create loading dots HTML
            const loadingDots = document.createElement('div');
            loadingDots.classList.add('loading-dots');
            loadingDots.style.display = 'flex'; // Set the initial display to flex
            loadingDots.innerHTML = `
                <span class="dot"></span>
                <span class="dot"></span>
                <span class="dot"></span>
            `;

            // Append loading dots to the button
            button.appendChild(loadingDots);
            const originalButtonText = button.querySelector('span').textContent;  // Save original text
            button.querySelector('span').textContent = 'Submitting';
   
            if (buttonId === 'brochure-submit-btn') {
                const key = button.getAttribute('data-br-id');
                let currentBrLink = BROCHURE_LINK.DR001;
                if (BROCHURE_LINK[key]) {
                    currentBrLink = BROCHURE_LINK[key];
                }
                if (CONSOLE_LOG) console.log("currentBrLink:", currentBrLink);

                const emailObject = {
                    subject: "Your Resource is Ready to Download from InsightsTap",
                    template: "download_brochure.xhtml",
                    calendar_link: "https://meetings.hubspot.com/spandav",
                    brochure_link: currentBrLink
                };
                const formState = await processFormInfo(formId, emailObject);
                
                // Remove loading dots after form submission
                button.removeChild(loadingDots);
                button.querySelector('span').textContent = originalButtonText; 

                if (formState) {
                    alert("Thank you for contacting Insightstap! You will receive the brochure via email. Additionally, you can book a meeting with us to learn more information.");
                    setTimeout(() => {
                        window.location.href = "./download-brochure";
                    }, 0);
                }
            } else if (buttonId === 'product-1-submit-btn') {
                const emailObject = {
                    subject: "Thank You for Requesting Beta Access to JobFeeder",
                    template: "product-1.xhtml",
                    calendar_link: "",
                };
                const formState = await processFormInfo(formId, emailObject);
                
                // Remove loading dots after form submission
                button.removeChild(loadingDots);
                button.querySelector('span').textContent = originalButtonText;

                if (formState) {
                    alert("Thank you for your interest in JobFeeder! We’re excited to share this journey with you as one of our beta users.");
                    setTimeout(() => {
                        // window.location.href = "./thank-you";
                    }, 0);
                }
            } else {
                const emailObject = {
                    subject: "Thank You for Reaching Out to Insightstap!",
                    template: "contact_us.xhtml",
                    calendar_link: "https://meetings.hubspot.com/spandav",
                };
                const formState = await processFormInfo(formId, emailObject);
                
                // Remove loading dots after form submission
                button.removeChild(loadingDots);
                button.querySelector('span').textContent = originalButtonText;

                if (formState) {
                    alert("Thank you for contacting Insightstap! Now, you can book a meeting with us to learn more information.");
                    setTimeout(() => {
                        window.location.href = "./thank-you";
                    }, 0);
                }
            }
        });
    }
}

async function processFormInfo(formId, emailObject) {
    if (CONSOLE_LOG) console.log("Process Form Info:", formId, "Email Object:\n", emailObject)

    const form = document.getElementById(formId);
    if (!form) {
        console.error(`Form with ID ${formId} not found.`);
        return false;
    }

    const inputs = form.querySelectorAll('input, select, textarea');

    let isValid = true; // Assuming this variable is declared outside the loop
    const formData = {};

    for (let i = 0; i < inputs.length; i++) {
        const input = inputs[i];
        const isRequired = input.getAttribute('data-required') === '1';

        if (isRequired && !input.value) {
            alert(`Please fill out the required field: ${input.placeholder || input.name}`);
            isValid = false;
            break; // Exit the loop immediately
        }

        if (input.value) {
            formData[input.name] = input.value;
        }
    }

    if (!isValid) return false;

    // if(formData.email !== ""){
    //     const validEmail = await validateEmailId(formData.email);
    //     if(!validEmail){
    //         return false;
    //     }
    // }
   
    emailObject.first_name = formData.firstname;

    let formObject = {
        tableName: form.getAttribute('data-table-id'),
        form: formData,
        mail: emailObject
    };
    
    const details = await getLocationDetails();
    if (details && Object.keys(details).length > 0) {
        if (CONSOLE_LOG) console.log('Location Details:', details);
        
        // Check the type of formData and handle accordingly
        if (formData instanceof FormData) {
            for (const [key, value] of Object.entries(details)) {
                formData.append(key, value);
            }
        } else {
            Object.assign(formData, details);
        }
    } else {
        if (CONSOLE_LOG) console.log('Could not fetch location details');
    }
    
    if (CONSOLE_LOG) console.log(formObject);
    return await submitFormData(form, formObject);
}

async function validateEmailId(emailId){
    try {
        if (CONSOLE_LOG) console.log("Email:", emailId);
        const result = await fetchDataFromPHP('/mail_vaildator.php', { email: emailId });
        if (result) {
            if (CONSOLE_LOG) console.log('Email validated.');
            return true;
        } else {
            return false;
        }
    } catch (err) {
        console.error('There was an error:', err);
        return false;
    }
}

async function submitFormData(form, formObject) {
    try {
        const result = await fetchDataFromPHP('/form_submission_v6.php', formObject);
        if (result) {
            //alert(SUCCESS_MESSAGE);
            form.reset();
            return true;
        } else {
            if (CONSOLE_LOG) console.log('Form submission failed.');
            return false;
        }
    } catch (err) {
        console.error('There was an error:', err);
        return false;
    }
}

async function submitFormDataToHubSpot(form) {
    try {
        // Create a FormData object from the form
        const formData = new FormData(form);

        // Extract form field values
        const name = formData.get('name'); 
        const email = formData.get('email'); 
        const message = formData.get('message');

        // Prepare the data to send to HubSpot
        const hubSpotData = {
            fields: [
                { property: 'firstname', value: name },
                { property: 'email', value: email },
                { property: 'message', value: message }
            ]
        };

        const result = await fetchDataFromPHP(`https://api.hubapi.com/contacts/v1/contact?hapikey=${HUBSPOT_API_KEY}`, hubSpotData);
        if (result) {
            //alert(SUCCESS_MESSAGE);
            form.reset();
            return true;
        } else {
            if (CONSOLE_LOG) console.log('Form submission failed.');
            return false;
        }
    } catch (err) {
        console.error('There was an error:', err);
        return false;
    }
}

// Initialize event listeners for buttons
handleSubmit('contact-submit-btn', 'contact-us-form');
handleSubmit('brochure-submit-btn', 'callback-form');
handleSubmit('e-books-submit-btn', 'e-books-form');
handleSubmit('product-1-submit-btn', 'product-1-form');

const hubspotData = {
    properties: [
        { property: "firstname", value: "John" },
        { property: "lastname", value: "Doe" },
        { property: "email", value: "john.doe@example.com" },
        { property: "phone", value: "+1234567890" },
        { property: "company", value: "Technoava Technologies" },
        { property: "message", value: "Looking for more information." },
        { property: "lifecyclestage", value: "lead" },
        { property: "hs_lead_status", value: "New" },
        { property: "product_list", value: "Product A, Product B" },
        { property: "website", value: "https://example.com" },
        { property: "dr_code", value: "DR1234" },
        { property: "mailing_city", value: "New York" },
        { property: "mailing_country", value: "USA" }
    ]
};
  

async function getLocationDetails() {
    try {
        let locationDetails = {};
        const currentURL = window.location.href;
        
        // Fetch details from ipapi.co or any geolocation API
        const response = await fetch('https://ipapi.co/json/');
        
        // Check if the response is valid
        if (!response.ok) {
            throw new Error('Failed to fetch location details');
        }
      
        // Parse the response JSON
        const data = await response.json();
      
      // Validate that data is an object
      if (data && typeof data === "object") {
        // Format the returned data
        locationDetails = {
          website: currentURL,
          country: `${data.region}, ${data.country_name}`, // Combining region and country name
          hs_country_region_code: data.country,          // ISO country code
          city: data.city,                               // City
        };
      }
      
      return locationDetails;
    } catch (error) {
      console.error('Error fetching location details:', error);
      return {}; // Return an empty object if an error occurs
    }
  }
  
  
  
