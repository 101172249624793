export const CONSOLE_LOG = true;
export const MEETING_LINK = "https://meetings.hubspot.com/spandav";
export const PHP_SERVER_URL = "https://insightstap.com/server/php";
export const HUBSPOT_API_KEY = "fflsjnflknu748979ondfs";
export const BROCHURE_LINK = {
    DR001: "https://insightstap.com/resources/brochure/InsightsTap%20-%20ppc-brochure-&-pricing-v1.pdf",
    DR002: "https://insightstap.com/resources/brochure/InsightsTap%20-%20hubspot-va-brochure-&-pricing.pdf",
    DR003: "https://insightstap.com/resources/brochure/InsightsTap%20-%20hubspot-design-and-development-brochure-&-pricing-v1.pdf",
    DR004: "https://insightstap.com/resources/brochure/InsightsTap%20-%20web-&-product-analytics-studio-brochure-&-pricing-v1.pdf",
    DR005: "https://insightstap.com/resources/brochure/InsightsTap%20-%20cold-outreach-brochure-&-pricing-v1.pdf",
    DROO6: "https://insightstap.com/resources/e-books/Revitalising%20B2B%20Real%20Estate.pdf",
}
export const E_BOOKS_LINK = {
    DROO6: "https://insightstap.com/resources/e-books/Revitalising%20B2B%20Real%20Estate.pdf",
}

export const menuData = [
    {
        label: "Home",
        link: "../",
    },
    {
        label: "Product",
        link: "#",
        children: [
            { label: "Job Feeder", link: "/product/jobfeeder/" },
        ],
    },
    {
        label: "Hire Now",
        link: "../#services",
        children: [
            { label: "Paid Ads Virtual Assistant", link: "/services/hire-ppc-va/" },
            { label: "HubSpot Virtual Assistant", link: "/services/hire-hubspot-va/" },
            { label: "Cold Outreach Virtual Assistant", link: "/services/hire-cold-outreach-va/" },
            { label: "HubSpot Developer", link: "/services/hubspot-development/" },
            { label: "Analytics Expert", link: "/services/analytics-studio/" },
        ],
    },
    {
        label: "Success Stories",
        link: "../success-stories/"
    },
    {
        label: "About",
        link: "../about-us",
    },
];

export const footerData = {
    description: "We're your innovation partner, delivering advanced solutions to elevate your business.",
    address: "Awfis, Rajarhat-2, 2A, 6th floor, Eco Space, New Town, North 24 Parganas, West Bengal, 700156, India",
    socialMedia: [
        { href: "#", iconClass: "fab fa-twitter" },
        { href: "#", iconClass: "fab fa-facebook-f" },
        { href: "#", iconClass: "fab fa-instagram" },
        { href: "#", iconClass: "fab fa-linkedin" },
        { href: "#", iconClass: "fab fa-github" }
    ],
    subMenu: [
        {
            title: "Navigation",
            links: [
                { text: "Home", url: "../" },
                { text: "About Us", url: "../about-us" },
                { text: "Case Study", url: "../success-stories/" },
                { text: "Testimonials", url: "../#testimonials" },
                { text: "Contact", url: "../contact-us" },
            ]
        },
        {
            title: "Services",
            links: [
                { text: "Hire PPC VA", url: "./services/hire-ppc-va/" },
                { text: "Hire HubSpot VA", url: "./services/hire-hubspot-va/" },
                { text: "Analytics Studio", url: "./services/hubspot-development/" },
                { text: "HubSpot Development", url: "./services/hubspot-development/" },
                { text: "Cold Outreach VA", url: "./services/hire-cold-outreach-va/" },
            ]
        },
        {
            title: "Quick Links",
            links: [
                { text: "Support", url: "mailto:contact@insightstap.com" },
                { text: "Privacy policy", url: "./legal/privacy-policy/" },
                { text: "Terms & Conditions", url: "./legal/terms-and-conditions/" },
                { text: "Refund Policy", url: "./legal/refund-policy/" }
            ]
        }
    ],
    footerBottom: "©Copyright 2023, All Rights Reserved by InsightsTap Solutions"
};

export const testimonials = [
    {
        id: 1,
        name: "Shyam H",
        location: "India",
        title: "HubSpot Optimization Excellence",
        feedback: "Thank you for helping us optimize various hubs on the enterprise and professional version of Hubspot. Highly commendable.",
        picture: "./1.webp",
        rating: 5,
    },
    {
        id: 2,
        name: "Maik J",
        location: "Germany",
        title: "Transformational Tech Marketing",
        feedback: "Ritesh's expertise in tech marketing and automation transformed our startup. His innovative strategies and clear communication drove remarkable brand growth and efficiency. Highly recommended!",
        picture: "./2.webp",
        rating: 4.8,
    },
    {
        id: 3,
        name: "Colin Bland",
        location: "Australia",
        title: "SaaS Product Mentorship",
        feedback: "Ritesh's deep knowledge and mentoring approach greatly enhanced my understanding of our complex SaaS product. He guided me through the funnel-building process, leaving me with valuable skills and a solid strategy.",
        picture: "./3.webp",
        rating: 5,
    },
    {
        id: 4,
        name: "Yew Friend",
        location: "Canada",
        title: "Startup Clarity and Strategy",
        feedback: "Thanks for the fantastic work, which cleared the mind blocks and confusion most startups face at the launch stage. I am excited to implement your designed SaaS Marketing plan.",
        picture: "./4.webp",
        rating: 4.6,
    },
    {
        id: 5,
        name: "Vash Naidoo288",
        location: "United Kingdom",
        title: "Seamless Marketing and Branding",
        feedback: "Ritesh's expertise and approachability made defining my marketing, brand, and funnel plan seamless. He ensures every detail is covered. Highly recommended!",
        picture: "./5.webp",
        rating: 4.9,
    },
    {
        id: 6,
        name: "James Slater",
        location: "United Kingdom",
        title: "Outstanding Sales Funnel Expertise",
        feedback: "Wonderful experience. Value received FAR exceeded the amount I paid. Would highly recommend to anyone look for an expert at building out sales funnels!",
        picture: "./6.webp",
        rating: 5.0,
    },
    {
        id: 7,
        name: "Arymal J",
        location: "Canada",
        title: "Enlightening Discovery Process",
        feedback: "Working with Ritesh was enlightening. His unmatched detail and methodology made the discovery process enjoyable and critical to our success. I’m eager to collaborate again.",
        picture: "./7.webp",
        rating: 4.3,
    },
    {
        id: 8,
        name: "Colleen Wilson",
        location: "United States",
        title: "Amazing Collaboration",
        feedback: "Ritesh was amazing to work with, I would hire him again to help and advise. Great guy!",
        picture: "./8.webp",
        rating: 4.5,
    },
    {
        id: 9,
        name: "David G",
        location: "Australia",
        title: "Clarity in Marketing Direction",
        feedback: "Ritesh brought clarity to our marketing direction, introducing effective strategies and resources. His friendly, communicative approach made him an invaluable team member. We’ll continue working with him.",
        picture: "./9.webp",
        rating: 5.0,
    },
    {
        id: 10,
        name: "Renee Kraft",
        location: "United States",
        title: "Email System and Campaign Strategies",
        feedback: "Took a great deal of time helping figure out the email system to use and creating campaign strategies for online marketing.",
        picture: "./10.webp",
        rating: 4.2,
    }
];
  
  